import React from 'react';

function PrivacyPolicy() {
  return (
    <main>
      <div className="container">
        <h1>Kebijakan Pribadi</h1>

        <p className="text-sm">Pembaruan Terakhir : Mei 2021</p>

        <p className="text-md">
          <b className="text-white">Databiota</b> (CV Ekologi Data Integra)
          menciptakan produk platform yang memungkinkan perusahaan, penelitian,
          pendidikan, konservasi lingkungan, badan kesehatan masyarakat,
          otoritas rumah sakit untuk monitoring, melacak,
          mengontekstualisasikan, mengantisipasi (mitigasi) hingga memprediksi
          resiko penyakit menular dan ekologi lainnya. Kebijakan privasi ini
          menjelaskan cara kami mengumpulkan, menggunakan, dan mengungkapkan
          informasi pribadi melalui situs web kami databiota.com, melalui
          penggunaan Anda atas platform berbasis web dan aplikasi kami, dan
          melalui interaksi kami yang lain dengan Anda.
        </p>

        <h2 className="text-xl">
          Bagaimana kami mengumpulkan dan menggunakan informasi Anda
        </h2>

        <ul>
          <li>
            <h3 className="text-lg text-white">Untuk membuat akun Anda :</h3>

            <p className="text-md">
              Anda tidak perlu membuat akun untuk mengunjungi situs web kami.
              Namun, diperlukan langganan untuk menggunakan produk dan layanan
              kami. Jika Anda mendaftar untuk berlangganan, kami akan
              mengumpulkan nama depan dan belakang, alamat email, jabatan, nama
              perusahaan, industri, lokasi, nomor telepon, dan kata sandi yang
              Anda buat. Kami menggunakan informasi ini untuk membuat dan
              mengelola akun Anda, menyediakan produk dan layanan yang Anda
              minta, memecahkan masalah yang mungkin Anda alami dengan produk
              dan untuk mengautentikasi Anda. Kami sangat menyarankan agar Anda
              tidak mengungkapkan kata sandi Anda kepada siapa pun. Kami tidak
              akan pernah meminta kata sandi Anda dalam komunikasi yang tidak
              diminta (seperti surat, panggilan telepon, atau pesan email). Jika
              Anda mengetahui adanya akses tidak sah ke atau penggunaan akun
              Anda, Anda harus segera memberi tahu kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">
              Untuk mempersonalisasi penggunaan produk kami oleh Anda: :
            </h3>

            <p className="text-md">
              Anda dapat memilih untuk memberikan informasi tambahan agar kami
              dapat menyesuaikan dan menyesuaikan penggunaan produk kami oleh
              Anda. Informasi ini mungkin termasuk preferensi Anda atau untuk
              beberapa kasus penggunaan, informasi anonim tentang riwayat
              perjalanan, input (tagging) area lahan, foto kasusm gejala dan
              informasi serupa lainnya. Kami menggunakan informasi ini untuk
              mempersonalisasi pengalaman pengguna Anda dan untuk memberi Anda
              wawasan, analisis (misalnya penyakit kontekstual dan informasi
              wabah yang relevan dengan informasi yang Anda berikan dan
              pemberitahuan tentang peristiwa (misalnya peristiwa penyakit) yang
              paling relevan bagi Anda). Saat Anda menggunakan aplikasi seluler
              kami (atau aplikasi web yang dioptimalkan untuk seluler), kami
              dapat meminta persetujuan Anda untuk mengumpulkan informasi lokasi
              Anda dengan mengakses koordinat GPS perangkat Anda. Kami
              menggunakan informasi ini untuk menyesuaikan wawasan kami dengan
              lokasi Anda saat ini. Anda dapat memilih keluar kapan saja dengan
              mematikan layanan lokasi Anda untuk aplikasi baik di dalam
              aplikasi atau di pengaturan perangkat seluler Anda.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">
              Untuk menanggapi pertanyaan Anda :
            </h3>

            <p className="text-md">
              Saat Anda menghubungi kami dengan komentar, pertanyaan, atau
              keluhan, Anda mungkin dimintai informasi yang mengidentifikasi
              Anda, seperti nama depan dan belakang, alamat email, jabatan dan
              nama perusahaan, dan informasi tambahan kami. perlu membantu kami
              segera menjawab pertanyaan Anda atau menanggapi pertanyaan Anda.
              Kami dapat menyimpan informasi ini untuk membantu Anda di masa
              depan dan untuk meningkatkan layanan pelanggan dan penawaran
              layanan kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Rekaman panggilan :</h3>

            <p className="text-md">
              Kami dapat memantau dan merekam percakapan telepon kami dengan
              Anda untuk tujuan pelatihan dan jaminan kualitas. Anda akan diberi
              pemberitahuan di awal setiap panggilan yang direkam. Jika Anda
              tidak ingin panggilan Anda direkam, harap beri tahu kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">
              Mengirim komunikasi pemasaran :
            </h3>

            <p className="text-md">
              Jika Anda mendaftar untuk menerima komunikasi pemasaran dari kami,
              kami dapat menggunakan informasi Anda untuk mengirim komunikasi
              termasuk buletin, berita tentang wabah di bidang minat Anda,
              ringkasan wabah, konservasi, dan semua terkait perlindungan
              ekologi secara bulanan, dan penawaran produk khusus atau baru.
              Kami juga dapat berkomunikasi dengan Anda melalui telepon. Anda
              dapat berhenti berlangganan kapan saja dengan mengeklik tautan
              "berhenti berlangganan" yang terdapat di bagian bawah setiap
              email. Atau, Anda dapat memilih untuk tidak menerima email atau
              komunikasi pemasaran telepon dengan menghubungi kami di informasi
              kontak yang disediakan di bagian "Hubungi Kami" di bawah. Harap
              dicatat bahwa Anda dapat terus menerima komunikasi transaksional
              atau terkait akun dari kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Survei dan Riset Pelanggan :</h3>

            <p className="text-md">
              Dari waktu ke waktu, kami mungkin menawarkan Anda kesempatan untuk
              berpartisipasi dalam salah satu survei kami atau riset pelanggan
              lainnya. Informasi yang diperoleh melalui survei dan penelitian
              pelanggan kami digunakan dalam bentuk agregat yang tidak dapat
              diidentifikasi secara pribadi untuk membantu kami memahami
              pelanggan kami dan untuk meningkatkan penawaran produk dan layanan
              kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Survei dan Riset Pelanggan :</h3>

            <p className="text-md">
              Dari waktu ke waktu, kami mungkin menawarkan Anda kesempatan untuk
              berpartisipasi dalam salah satu survei kami atau riset pelanggan
              lainnya. Informasi yang diperoleh melalui survei dan penelitian
              pelanggan kami digunakan dalam bentuk agregat yang tidak dapat
              diidentifikasi secara pribadi untuk membantu kami memahami
              pelanggan kami dan untuk meningkatkan penawaran produk dan layanan
              kami.
            </p>
          </li>
        </ul>

        <h2 className="text-xl">Bagaimana kami membagikan informasi Anda</h2>

        <p className="text-md">
          Kami tidak menjual atau mengungkapkan informasi pribadi Anda kepada
          pihak ketiga tanpa persetujuan Anda, kecuali sebagaimana ditetapkan di
          bawah ini atau sebagaimana diharuskan atau diizinkan oleh hukum.
        </p>

        <ul>
          <li>
            <h3 className="text-lg text-white">Penyedia Layanan :</h3>

            <p className="text-md">
              Informasi pribadi Anda akan ditransfer (atau disediakan) kepada
              pihak ketiga tertentu yang menyediakan layanan atas nama kami.
              Kami menggunakan penyedia layanan untuk menyediakan layanan
              seperti hosting situs web, aplikasi hosting, pemrosesan
              pembayaran, dan menyediakan layanan periklanan dan pemasaran.
              Penyedia layanan kami hanya diberikan informasi yang mereka
              butuhkan untuk menjalankan fungsi yang ditentukan dan tidak
              diizinkan untuk menggunakan atau mengungkapkan informasi pribadi
              untuk pemasaran mereka sendiri atau tujuan lain. Penyedia layanan
              kami mungkin berlokasi di Indonesia.
            </p>
          </li>
        </ul>

        <h2 className="text-xl">Informasi tentang situs web dan produk kami</h2>

        <ul>
          <li>
            <h3 className="text-lg text-white">Mengunjungi situs web kami :</h3>

            <p className="text-md">
              Secara umum, Anda dapat mengunjungi situs web kami tanpa memberi
              tahu kami siapa Anda atau mengirimkan informasi pribadi apa pun.
              Namun, kami mengumpulkan alamat IP (protokol Internet) semua
              pengunjung situs web kami dan informasi terkait lainnya seperti
              permintaan halaman, jenis browser, sistem operasi, dan waktu
              rata-rata yang dihabiskan di situs web kami. Kami menggunakan
              informasi ini untuk membantu kami memahami aktivitas situs web
              kami, memecahkan masalah dan untuk memantau serta meningkatkan
              situs web dan produk kami.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Cookie :</h3>

            <p className="text-md">
              Situs web dan produk kami menggunakan teknologi yang disebut
              "cookie". Cookie adalah elemen kecil dari data yang dikirimkan
              situs web kami ke browser pengguna, yang kemudian dapat disimpan
              di hard drive pengguna sehingga kami dapat mengenali komputer atau
              perangkat pengguna saat mereka kembali. Misalnya, cookie digunakan
              saat masuk untuk membuka kembali tampilan Dashnoard terakhir yang
              Anda gunakan. Anda dapat mengatur browser Anda untuk memberi tahu
              Anda ketika Anda menerima cookie atau untuk tidak menerima cookie
              tertentu. Namun, jika Anda memutuskan untuk tidak menerima cookie
              dari situs web atau produk kami, Anda mungkin tidak dapat
              memanfaatkan semua fitur ini.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Analytics :</h3>

            <p className="text-md">
              Kami dapat menggunakan pihak ketiga seperti Google Analytics untuk
              membantu kami mengumpulkan dan menganalisis informasi tentang area
              yang dikunjungi di situs web (seperti halaman yang paling banyak
              dibaca, waktu yang dihabiskan, istilah pencarian, dan data
              keterlibatan lainnya) untuk mengevaluasi dan meningkatkan
              pengalaman pengguna, produk, aplikasi seluler, dan situs web kami.
              Kami juga menggunakan tag piksel atau teknologi lain untuk melacak
              pembukaan email dan rasio klik-tayang untuk mengukur minat
              pengguna dan pola lalu lintas. Pihak ketiga ini dapat menggunakan
              cookie dan teknologi pelacakan lainnya. Untuk informasi lebih
              lanjut tentang Google Analytics atau untuk mencegah penyimpanan
              dan pemrosesan data ini (termasuk alamat IP Anda) oleh Google,
              Anda dapat mengunduh dan memasang plugin browser yang tersedia di
              tautan berikut:{' '}
              <a
                className="text-link"
                href="https://tools.google.com/dlpage/gaoptout?hl=id"
              >
                https://tools.google.com/dlpage/gaoptout?hl=id
              </a>{' '}
              . Anda juga dapat memperoleh informasi tambahan tentang privasi
              dan keamanan data Google Analytics di tautan berikut :{' '}
              <a
                className="text-link"
                href="https://policies.google.com/technologies/partner-sites"
              >
                https://policies.google.com/technologies/partner-sites
              </a>
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Tautan Pihak Ketiga :</h3>

            <p className="text-md">
              Situs web kami mungkin berisi tautan ke situs web lain yang tidak
              dimiliki atau dioperasikan oleh Zoonostic. Kami menyediakan tautan
              ke situs web pihak ketiga untuk kenyamanan pengguna. Tautan ini
              tidak dimaudkan sebagai dukungan atau rujukan ke situs web yang
              ditautkan. Situs web yang ditautkan memiliki kebijakan privasi,
              pemberitahuan, dan ketentuan penggunaan yang terpisah dan
              independen. Kami tidak memiliki kendali apa pun atas situs web
              tersebut, dan oleh karena itu kami tidak bertanggung jawab atau
              berkewajiban atas cara organisasi yang mengoperasikan situs web
              tertaut tersebut dapat mengumpulkan, menggunakan atau
              mengungkapkan, mengamankan, dan memperlakukan informasi pribadi.
              Kami mendorong Anda untuk membaca kebijakan privasi dari setiap
              situs web yang Anda kunjungi.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Perlindungan Anda :</h3>

            <p className="text-md">
              Kami menyimpan informasi pribadi Anda secara rahasia di server
              yang berlokasi di Indonesia. Kami telah menerapkan langkah-langkah
              administratif, teknis, dan fisik yang wajar dalam upaya untuk
              melindungi informasi pribadi dalam pengawasan dan kendali kami
              terhadap pencurian, kehilangan, dan akses, penggunaan, modifikasi,
              dan pengungkapan yang tidak sah. Kami membatasi akses ke informasi
              pribadi atas dasar yang perlu diketahui untuk karyawan dan
              penyedia layanan resmi yang memerlukan akses untuk memenuhi
              persyaratan pekerjaan mereka.
            </p>

            <p className="text-md">
              Kami memiliki proses penyimpanan catatan yang dirancang untuk
              menyimpan informasi pribadi tidak lebih dari yang diperlukan untuk
              tujuan yang ditetapkan di sini atau sebagaimana diperlukan untuk
              memenuhi persyaratan hukum atau bisnis.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Akses ke informasi Anda :</h3>

            <p className="text-md">
              Tunduk pada hukum yang berlaku, Anda memiliki hak untuk mengakses,
              memperbarui, dan memperbaiki ketidakakuratan informasi pribadi
              Anda dalam pengawasan atau kendali kami. Anda dapat meminta akses,
              pembaruan dan koreksi ketidakakuratan dalam informasi pribadi Anda
              dalam pengawasan atau kendali kami dengan mengirim email atau
              menulis kepada kami di informasi kontak yang ditetapkan di bawah
              ini. Kami dapat meminta informasi pribadi tertentu untuk tujuan
              memverifikasi identitas individu yang mencari akses ke catatan
              informasi pribadinya
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Pembaruan kebijakan :</h3>

            <p className="text-md">
              Kami dapat memperbarui kebijakan privasi ini secara berkala untuk
              mencerminkan perubahan pada praktik privasi kami. Kami mendorong
              Anda untuk meninjau halaman ini secara berkala untuk memastikan
              Anda memahami perubahan tersebut. Kami akan menunjukkan di bagian
              atas kebijakan privasi ini ketika baru-baru ini diperbarui.
            </p>
          </li>

          <li>
            <h3 className="text-lg text-white">Hubungi kami :</h3>

            <p className="text-md">
              Jika Anda memiliki pertanyaan atau komentar tentang kebijakan
              privasi ini atau cara kami atau penyedia layanan kami
              memperlakukan informasi pribadi Anda, atau untuk meminta akses ke
              pengumpulan informasi pribadi Anda, silakan hubungi Tim Privasi
              kami di :
            </p>

            <h4>Databiota</h4>
            <address className="text-md">
              Jipangan, RT/RW.007/000, Kasongan, Kasihan Bantul, D.I.Yogyakarta
              - Indonesia
            </address>

            <p className="text-md">
              Email : <a href="mailto:info@databiota.com">info@databiota.com</a>
            </p>
          </li>
        </ul>
      </div>
    </main>
  );
}

export default PrivacyPolicy;
