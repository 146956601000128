import React, { lazy, Suspense } from 'react';
import Loading from '../../components/atoms/Loading';
const Hero = lazy(() => import('../../components/organisms/Hero/Hero'));
const Canvas = lazy(
  () => import('../../components/molecules/Particle/Particle')
);

function LandingPage() {
  return (
    <>
      <Suspense fallback="...">
        <Canvas />
      </Suspense>

      <Suspense fallback={<Loading height="100vh" />}>
        <Hero />
      </Suspense>
    </>
  );
}

export default LandingPage;
