import React, { memo } from 'react';
import styled from 'styled-components';
import LogoDatabiota from '../../assets/icons/databiota-logo.svg';
import IconLogoDatabiota from '../../assets/icons/databiota-logo-icon.png';

type LogoIconProps = {
  width: string;
  height: string;
};

type LogoProps = {
  width: string;
  height: string;
};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoIcon = memo(({ width, height, ...rest }: LogoIconProps) => {
  return (
    <LogoContainer {...rest}>
      <img
        width={width}
        height={height}
        src={IconLogoDatabiota}
        alt="Logo Icon Zoonostic"
      />
    </LogoContainer>
  );
});

export const Logo = memo(({ width, height, ...rest }: LogoProps) => {
  return (
    <LogoContainer {...rest}>
      <img
        width={width}
        height={height}
        src={LogoDatabiota}
        alt="Logo Icon Zoonostic"
      />
    </LogoContainer>
  );
});
