import { ThemeProvider } from 'styled-components';
import { lightTheme } from './themes/theme';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './themes/globalStyles';
import LandingPage from './pages/LandingPage/LandingPage';
import Navbar from './components/organisms/Navbar/Navbar';
import Footer from './components/organisms/Footer/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import useScrollToTop from './hooks/useScrollToTop';

function App() {
  const ScrollToTop = useScrollToTop;

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />

      <Router>
        <ScrollToTop />

        <div className="body-wrap boxed-container">
          <Navbar />
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route path="/kebijakan-pribadi">
              <PrivacyPolicy />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
