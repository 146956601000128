import { Link } from 'react-router-dom';
import { Logo } from '../../atoms/Logo';

export default function Navbar() {
  return (
    <header className="site-header" style={{ zIndex: 10000 }}>
      <div className="container">
        <div className="site-header-inner">
          <div className="brand header-brand">
            <h1 className="m-0">
              <Link to="/">
                <Logo width="250" height="auto" />
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </header>
  );
}
