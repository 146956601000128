import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  html,
  body {
    background: #141516;
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;

    &::-webkit-scrollbar-track
    {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #141516;
    }

    &::-webkit-scrollbar
    {
      width: 6px;
      background-color: #141516;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: rgba(255,255,255,0.05);
    }
  }

  html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background: #141516;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
hr {
  border: 0;
  display: block;
  height: 1px;
  background: rgba(69, 76, 76, 0.5);
  margin-top: 24px;
  margin-bottom: 24px;
}
ul,
ol {
  margin-bottom: 24px;
  padding-left: 24px;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li > ul,
li > ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 24px;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 24px;
  margin-bottom: 24px;
}
figure {
  margin: 24px 0;
}
figcaption {
  font-size: 16px;
  line-height: 30px;
  padding: 8px 0;
}
img,
svg {
  display: block;
}
table {
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
}
tr {
  border-bottom: 1px solid #454c4c;
}
th {
  text-align: left;
}
th,
td {
  padding: 10px 16px;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
html {
  font-size: 20px;
  line-height: 32px;
}
body {
  color: #a3b5c7;
  font-size: 1rem;
}
body,
button,
input,
select,
textarea, a {
  font-family: 'Heebo', sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  clear: both;
  color: #fff;
  font-weight: 600;
}
h1,
.h1 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.2px;
}
@media (min-width: 641px) {
  h1,
  .h1 {
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0px;
  }
}
h2,
.h2 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  h2,
  .h2 {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.2px;
  }
}
h3,
.h3,
blockquote {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  h3,
  .h3,
  blockquote {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.1px;
  }
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.1px;
}

.text-sm {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

.text-md {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

.text-lg {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

.text-xl {
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

.text-white {
  color: #fff !important;
}

.text-link {
  color: #8ab4f8;
}

h1,
h2,
.h1,
.h2 {
  margin-top: 48px;
  margin-bottom: 16px;
}
h3,
.h3 {
  margin-top: 36px;
  margin-bottom: 12px;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 24px;
  margin-bottom: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 24px;
}

.is-boxed {
  background: #1e1f21;
}
.body-wrap {
  background: #141516;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px rgba(20, 21, 22, 0.5);
}
main {
  flex: 1 0 auto;
}

.container,
.container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 481px) {
  .container,
  .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.container {
  max-width: 1128px;
}
.container-sm {
  max-width: 848px;
}
.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}

`;

export default GlobalStyle;
