import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
  }
}

export const lightTheme: DefaultTheme = {
  primaryColor: 'rgb(255, 255, 255)',
  secondaryColor: 'rgb(16, 19, 44)',
  fontFamily: '"Roboto", sans-serif;',
};

export const darkTheme: DefaultTheme = {
  primaryColor: 'rgb(16, 19, 44)',
  secondaryColor: 'rgb(255, 255, 255)',
  fontFamily: '"Roboto", sans-serif;',
};
